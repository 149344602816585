import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"illustrations"} />
		<Helmet>
			<title>
				Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:title"} content={"Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header mix-blend-mode="difference" sm-padding="0px 0px 0px 0px">
				<Override slot="link" color="--darkL2" />
				<Override slot="link1" color="--darkL2" />
				<Override slot="link2" color="--darkL2" />
			</Components.Header>
			<Section grid-template-columns="repeat(4, 1fr)">
				<Override slot="SectionContent" display="grid" grid-template-columns="repeat(4, 1fr)" grid-gap="16px 24px" />
				<Text margin="0px 0px 48px 0px" grid-column="1/5" font="--headline2" color="--darkL2">
					Иллюстрации
				</Text>
				<Components.ImageViewer
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Простое условие для пропуска лишнего вопроса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					max-width="none"
					width="100%"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Простое условие для пропуска лишнего вопроса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Простое условие для пропуска лишнего вопроса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Простое условие для пропуска лишнего вопроса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Простое условие для пропуска лишнего вопроса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Простое условие для пропуска лишнего вопроса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Простое условие для пропуска лишнего вопроса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Простое условие для пропуска лишнего вопроса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(52528996, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
			<script async={false} src={"https://www.googletagmanager.com/gtag/js?id=G-5FMRH52STD"} place={"endOfHead"} rawKey={"62d57fa6534ed0228e31f79a"} />
			<script place={"endOfHead"} rawKey={"62d57fb994ee59fbdc7b583c"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-5FMRH52STD');"}
			</script>
		</RawHtml>
	</Theme>;
});